import { Box, Button, Checkbox, Divider, Flex, Text, theme, useToast } from '@chakra-ui/react'
import React, { FC, useEffect, useState } from 'react'

import { Header } from '../../Components/Header'
import { Logo } from '../../Components/Logo'
import { useScreenSizes } from '../../utils/screenSizes'

import { useNavigate } from 'react-router-dom'

import { TextArea } from '../../Components/Form/TextArea'
import { SubmitHandler, useForm, useFieldArray } from 'react-hook-form'
import { Input } from '../../Components/Form/Input'
import { ToastCustom } from '../../Components/ToastCustom'
import { UpdateCustomFieldsUseCase } from '../../Usecase/Ai/update-custom-fields-usecase'
import { IAssistantCustomFieldsProps, IUpdateCustomFieldsItems, TListCustomFieldsResponse } from '../../Services/protocols/service-custom-fields'
import { ListCustomFieldsUseCase } from '../../Usecase/Ai/list-custom-fields-usecase'
import { Loader } from '../../Components/Loader'

interface Props {
  updateCustomFieldsUseCase: UpdateCustomFieldsUseCase
  listCustomFieldsUseCase: ListCustomFieldsUseCase
}

interface CustomField {
  id: string
  name: string
  description: string
  isActive: boolean
  activationTime: number
  columnName: string
}

interface FormData {
  customFields: CustomField[]
}

const CustomFieldsPage: FC<Props> = ({ updateCustomFieldsUseCase, listCustomFieldsUseCase }) => {
  const { isMobile } = useScreenSizes()
  const toast = useToast()
  const navigation = useNavigate()
  const [loading, setLoading] = useState(false)
  const [customFieldsData, setCustomFieldsData] = useState<TListCustomFieldsResponse>()

  const { control, handleSubmit, register, formState, watch, setValue } = useForm<FormData>({
    defaultValues: {
      customFields: []
    }
  })

  const { fields, replace } = useFieldArray({
    control,
    name: 'customFields',
    keyName: 'id'
  })

  const customFields = watch('customFields')

  const handleNext: SubmitHandler<FormData> = async (data) => {
    try {
      setLoading(true)
      const fieldsData = data.customFields

      const validatePayload = fieldsData.map(item => {
        const obj: IUpdateCustomFieldsItems = {
          name: item.name,
          assistantType: 'sales',
          description: item.description,
          columnName: item.columnName,
          isActive: item.isActive
        }
        return obj
      })

      const fieldWithValues = validatePayload.filter(item => item.isActive)

      if (!fieldWithValues.every(i => !!i.description)) {
        toast({
          position: 'top-right',
          render: () => <ToastCustom type='fail' title='Erro' description='Preencha a descrição de todos os campos selecionados' />
        })
        return
      }

      const normalizeValues = validatePayload.map(item => {
        const obj: IUpdateCustomFieldsItems = {
          name: item.name,
          assistantType: 'sales',
          description: item.description || 'não informado',
          columnName: item.columnName,
          isActive: item.isActive
        }
        return obj
      })

      const result = await updateCustomFieldsUseCase.handle({ customFields: normalizeValues || [] })

      if (result.isFailure) {
        toast({
          position: 'top-right',
          render: () => <ToastCustom type='fail' title='Erro' description={result.error?.error ?? 'Erro ao cadastrar os campos personalizados.'} />
        })
        return
      }

      toast({
        position: 'top-right',
        render: () => <ToastCustom type='success' title='Sucesso' description='Campos personalizados cadastrados.' />
      })
    } catch (error) {
      toast({
        position: 'top-right',
        render: () => <ToastCustom type='fail' title='Erro' description={error.response?.data?.error ?? 'Erro ao salvar. Por favor, tente novamente'} />
      })
    } finally {
      setLoading(false)
    }
  }

  const listCustomFields = async (): Promise<void> => {
    try {
      setLoading(true)
      const result = await listCustomFieldsUseCase.handle()

      if (result.isFailure) {
        toast({
          position: 'top-right',
          render: () => <ToastCustom type='fail' title='Erro' description={result.error?.error ?? 'Erro ao listar os campos personalizados.'} />
        })
        return
      }

      const data = result.getValue()

      if (data) {
        setCustomFieldsData(data)

        const initialFieldValues = data.csvCustomFields.map((i, idx) => {
          const assistantField = data.assistantCustomFields?.find(item => item.name === i.columnField)
          const isActive = !!assistantField?.isActive
          const description = isActive ? assistantField?.description || '' : ''
          const activationTime = isActive ? Date.now() - (idx + 1) * 1000 : 0

          return {
            id: i.columnField,
            name: i.columnField,
            description,
            isActive,
            activationTime,
            columnName: i.columnName
          }
        })

        replace(initialFieldValues)
      }
    } catch (error) {
      toast({
        position: 'top-right',
        render: () => <ToastCustom type='fail' title='Erro' description={error.response?.data?.error ?? 'Erro ao listar os campos personalizados.'} />
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const load = async () => {
      await listCustomFields()
    }
    load()
  }, [])

  const selectedFieldsExist = customFields.some(field => field.isActive)

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (type === 'change' && name?.endsWith('isActive')) {
        const fieldName = name
        const indexMatch = fieldName.match(/customFields\.(\d+)\.isActive/)
        if (indexMatch) {
          const index = parseInt(indexMatch[1], 10)
          const isActive = value && value?.customFields && value?.customFields[index]?.isActive
          if (isActive) {
            setValue(`customFields.${index}.activationTime`, Date.now())
          } else {
            setValue(`customFields.${index}.activationTime`, 0)
          }
        }
      }
    })
    return () => subscription.unsubscribe()
  }, [watch, setValue])

  return (
    <Flex flexDirection={isMobile ? 'column' : 'row'} flex={1}>

      <Header />
      {loading && <Loader />}

      <Flex flex={1} direction="column" paddingTop={isMobile ? '0px' : '41px'} overflowY='hidden' >

        {!isMobile && <Logo />}

        <Flex direction="column" alignItems='center' padding={0} height='100%' overflow='scroll' as="form" onSubmit={handleSubmit(handleNext)} pb='50px' >
          <Box w='70%'>

            <Text fontSize='21px' mt='30px'>Campos Customizados</Text>
            <Text fontSize='16px'>Selecione quais campos você deseja importar</Text>

            <Flex flexWrap='wrap' my='40px'>
              {customFields.map((field, index) => (
                <Box padding='10px' key={field.id}>
                  <Checkbox
                    {...register(`customFields.${index}.isActive`)}
                    defaultChecked={field.isActive}
                  >
                    {field.name}
                  </Checkbox>
                </Box>
              ))}
            </Flex>

            <Text fontSize='21px' mb='40px'>Campos Selecionados:</Text>

            {selectedFieldsExist
              ? customFields
                .filter(field => field.isActive)
                .sort((a, b) => b.activationTime - a.activationTime)
                .map((field) => {
                  const index = customFields.findIndex(f => f.id === field.id)
                  return (
                      <Box key={field.id} mb='50px'>
                        <Input
                          type="text"
                          label="Campo"
                          isReadOnly
                          labelStyle={{ fontWeight: '700', color: theme.colors.gray[900], marginBottom: 5 }}
                          style={{ borderRadius: 12, borderColor: '#B1C0E0', marginBottom: 15 }}
                          _placeholder={{ color: 'gray.third' }}
                          {...register(`customFields.${index}.name`)}
                        />

                        <TextArea
                          placeholder="O que este campo significa?"
                          label="Descrição"
                          labelStyle={{ fontWeight: '700', color: theme.colors.gray[900], marginBottom: 5 }}
                          style={{ borderRadius: 12, borderColor: '#B1C0E0', marginBottom: 15 }}
                          _placeholder={{ color: 'gray.third' }}
                          {...register(`customFields.${index}.description`)}
                        />

                        <Divider mb='40px' mt='40px' />
                      </Box>
                  )
                })
              : <Text fontSize='16px'>Você não possui nenhum campo customizado selecionado.</Text>}

          </Box>

          <Flex w='70%' flexDirection='column' alignItems='flex-end'>
            <Button
              type="submit"
              w='278px'
              height='49px'
              borderRadius='40px'
              mt="36px"
              bg="gray.900"
              color="gray.800"
              fontWeight='400'
              isLoading={formState.isSubmitting}
              ml='auto'
              disabled={!selectedFieldsExist}
            >
              Salvar
            </Button>
          </Flex>

        </Flex>
      </Flex>

    </Flex>
  )
}

export { CustomFieldsPage }


import {
  ChatApiRemoteService,
  TSendMessageDTO,
  TSendMessageResponse
} from '../../Services/protocols/service-chat'
import { Result } from '../../Shared/Protocol/protocol-result'
import { IUsecase } from '../../Shared/Protocol/protocol-usecase'

export class SendMessageUseCase
implements IUsecase<TSendMessageDTO, TSendMessageResponse> {
  constructor (private readonly repository: ChatApiRemoteService) {}

  public async handle ({ message, threadId, initialData, version }: TSendMessageDTO): Promise<Result<TSendMessageResponse>> {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await this.repository.sendMessage({ message, threadId, initialData, version })

      if (response.isFailure) {
        return response
      }

      const result = response.getValue()

      if (result === null) {
        return Result.fail(new Error('Erro ao enviar a mensagem'))
      }

      return Result.ok(result)
    } catch (error: any) {
      return Result.fail(error)
    }
  }
}

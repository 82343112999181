import { ChatApiRemoteService, IChatEntity } from '../../Services/protocols/service-chat'

import { Result } from '../../Shared/Protocol/protocol-result'
import { IUsecase } from '../../Shared/Protocol/protocol-usecase'

export class GetMessageUseCase implements IUsecase<string, IChatEntity> {
  constructor (private readonly repository: ChatApiRemoteService) {}

  public async handle (chatId: string): Promise<Result<IChatEntity>> {
    try {
      const response = await this.repository.getMessage(chatId)

      const data = response.getValue()

      return Result.ok(data ?? undefined)
    } catch (error: any) {
      console.log('respons error: ', error)
      return Result.fail(error)
    }
  }
}

import {
  ChatApiRemoteService,
  TCreatThreadIdResponse
} from '../../Services/protocols/service-chat'
import { Result } from '../../Shared/Protocol/protocol-result'
import { IUsecase } from '../../Shared/Protocol/protocol-usecase'

export class CreateThreadUseCase
implements IUsecase<string, TCreatThreadIdResponse> {
  constructor (private readonly repository: ChatApiRemoteService) {}

  public async handle (aiVersion: string): Promise<Result<TCreatThreadIdResponse>> {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await this.repository.createThreadId(aiVersion)

      if (response.isFailure) {
        return response
      }

      const result = response.getValue()

      if (result === null) {
        return Result.fail(new Error('Erro ao criar a thread'))
      }

      return Result.ok(result)
    } catch (error: any) {
      return Result.fail(error)
    }
  }
}

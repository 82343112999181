import { object, string } from 'yup'
import {
  AuthenticationApiRemoteService,
  TAuthLoginDTO,
  TAuthLoginResponse
} from '../../Services/protocols/service-authentication'
import { CUSTOMER } from '../../Config/constants'
import { StorageLocalstorage } from '../../Shared/storage/storage-localstorage'
import { IFormUsecase } from '../../Shared/Protocol/protocol-form-usecase'
import { Result } from '../../Shared/Protocol/protocol-result'
import { setCookie } from '../../utils/cookies'
import { DecodeJwt } from '../../utils/decode-jwt'

export class LoginUseCase
implements IFormUsecase<TAuthLoginDTO, TAuthLoginResponse> {
  constructor (private readonly repository: AuthenticationApiRemoteService) {}

  public async handle (
    data: TAuthLoginDTO
  ): Promise<Result<TAuthLoginResponse>> {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await this.repository.login(data)

      if (response.isFailure) {
        return response
      }

      const result = response.getValue()

      if (result === null) {
        return Result.fail(new Error('Erro ao realizar login'))
      }

      const { token, refreshToken, metabaseId, metabaseToken } = result

      const decoded = DecodeJwt(token)

      if (decoded) {
        console.log('decoded: ', decoded)
        result.customer = String(decoded?.customer)
        result.email = String(decoded?.userEmail)

        StorageLocalstorage.set(`${CUSTOMER}:customer`, {
          customer: decoded?.customer
        })

        StorageLocalstorage.set(`${CUSTOMER}:email`, {
          email: decoded?.userEmail
        })
      }

      StorageLocalstorage.set(`${CUSTOMER}:token`, {
        token
      })

      StorageLocalstorage.set(`${CUSTOMER}:refresh-token`, {
        refreshToken
      })

      StorageLocalstorage.set(`${CUSTOMER}:metabase-id`, {
        metabaseId
      })

      StorageLocalstorage.set(`${CUSTOMER}:metabase-token`, {
        metabaseToken
      })

      // setCookie({ name: 'metabase.EMBEDDED_SESSION', value: metabaseId, days: 14 })
      // setCookie({ name: 'metabase.TIMEOUT', value: 'alive', days: 14 })

      return Result.ok(result)
    } catch (error: any) {
      return Result.fail(error)
    }
  }

  public validation = object({
    username: string().required('E-mail é obrigatório'),
    password: string().required('Senha é obrigatória')
  })
}

import { CUSTOMER } from '../../Config/constants'

export class StorageLocalstorage {
  constructor () {}

  public static set (
    key: `${typeof CUSTOMER}:user` | `${typeof CUSTOMER}:token` | `${typeof CUSTOMER}:refresh-token` | `${typeof CUSTOMER}:metabase-id` | `${typeof CUSTOMER}:metabase-token` | `${typeof CUSTOMER}:customer` | `${typeof CUSTOMER}:email`,
    value: any
  ): void {
    localStorage.setItem(key, JSON.stringify(value))
  }

  public static get (
    key: `${typeof CUSTOMER}:user` | `${typeof CUSTOMER}:token` | `${typeof CUSTOMER}:metabase-token` | `${typeof CUSTOMER}:customer` | `${typeof CUSTOMER}:email`
  ): any {
    const value = localStorage.getItem(key)

    return value ? JSON.parse(value) : null

    // if (value && !value?.includes('token')) {
    //   return { token: value }
    // }

    // // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
    // if (value && value?.includes('token')) {
    //   return JSON.parse(value)
    // }

    // return value ? JSON.parse(value) : null
  }

  public static clearAll (): void {
    localStorage.clear()
  }
}

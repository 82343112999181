import { jwtDecode } from 'jwt-decode'

export const DecodeJwt = (token: string): any | undefined => {
  try {
  // Decodifica o JWT
    const decodedToken: any = jwtDecode(token)
    console.log('decodedToken: ', decodedToken)
    // Acessa o campo 'customer' do token
    const decoded = decodedToken

    return decoded
  } catch (error) {
    console.error('Erro ao decodificar o token:', error)
  }
}

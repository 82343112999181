
import { CustomFieldsApiRemoteService, TListCustomFieldsResponse } from '../../Services/protocols/service-custom-fields'
import { Result } from '../../Shared/Protocol/protocol-result'
import { IUsecase } from '../../Shared/Protocol/protocol-usecase'

export class ListCustomFieldsUseCase implements IUsecase<void, TListCustomFieldsResponse> {
  constructor (private readonly repository: CustomFieldsApiRemoteService) {}

  public async handle (): Promise<Result<TListCustomFieldsResponse>> {
    try {
      const response = await this.repository.listFields()

      if (response.isFailure) {
        return Result.fail(new Error('Erro ao buscar os dados do custom fields'))
      }

      const result = response.getValue()

      if (result === null) {
        return Result.fail(new Error('Erro ao buscar os dados do custom fields'))
      }

      return Result.ok(result)
    } catch (error: any) {
      console.log('respons error: ', error)
      return Result.fail(error)
    }
  }
}

import { AxiosInstance } from 'axios'
import { Result } from '../../Shared/Protocol/protocol-result'

export interface TCreatThreadIdResponse {
  threadId: string
}

export interface TSendMessageDTO {
  message: string
  threadId: string
  initialData: boolean | null
  version: number
}
export interface TSendMessageResponse {
  chatId: string
  message: string
}

export interface TRatingMessageDTO {
  chatId: string
  rating: string | undefined
}

export interface IChatEntity {
  id: string
  question: string
  answer?: string
  rating?: string
  aiTracking?: string

}

export class ChatApiRemoteService {
  constructor (private readonly service: AxiosInstance) {}

  public getMessage = async (chatId: string): Promise<Result<IChatEntity>> => {
    try {
      const result = await this.service.get(`/chat/${chatId}`)

      return Result.ok(result.data)
    } catch (error: any) {
      throw error?.response?.data
    }
  }

  public createThreadId = async (aiVersion: string): Promise<Result<TCreatThreadIdResponse>> => {
    try {
      const result = await this.service.post('/thread', { aiVersion })

      return Result.ok(result.data)
    } catch (error: any) {
      throw error?.response?.data
    }
  }

  public sendMessage = async ({ message, threadId, initialData, version }: TSendMessageDTO): Promise<Result<TSendMessageResponse>> => {
    try {
      const result = await this.service.post('/chat', { message, threadId, initialData, aiVersion: version })

      return Result.ok(result.data)
    } catch (error: any) {
      throw error?.response?.data
    }
  }

  public ratingMessage = async ({ chatId, rating }: TRatingMessageDTO): Promise<Result<void>> => {
    try {
      const result = await this.service.patch(`/chat/${chatId}`, { rating })

      return Result.ok(result.data)
    } catch (error: any) {
      throw error?.response?.data
    }
  }
}

import { AxiosInstance } from 'axios'
import { Result } from '../../Shared/Protocol/protocol-result'

export interface TListFavoritesResponse {
  id: string
  isActive: boolean
  name: string
// eslint-disable-next-line @typescript-eslint/no-unused-expressions
}[]

export interface ICsvCustomFielldsProps {
  columnName: string
  columnField: string
  columnSystemName: string
}

export interface IAssistantCustomFieldsProps {
  id: string
  createdAt: string
  updatedAt: string
  isActive: boolean
  name: string
  columnName: string
  description: string
  assistantId: string
  assistantType: 'SALES' | 'MIDIAS'
}
export interface TListCustomFieldsResponse {
  csvCustomFields: ICsvCustomFielldsProps[]
  assistantCustomFields: IAssistantCustomFieldsProps[]
}

export interface IUpdateCustomFieldsItems {
  name: string
  assistantType: string
  description: string
  columnName: string
  isActive: boolean
}

export interface IUpdateCustomFields {
  customFields: IUpdateCustomFieldsItems[]
}

export class CustomFieldsApiRemoteService {
  constructor (private readonly service: AxiosInstance) {}

  public updateFields = async (data: IUpdateCustomFields): Promise<Result<void>> => {
    try {
      await this.service.put('/custom-fields', data)

      return Result.ok()
    } catch (error: any) {
      throw error?.response?.data
    }
  }

  public listFields = async (): Promise<Result<TListCustomFieldsResponse>> => {
    try {
      const result = await this.service.get('/custom-fields/csv')

      return Result.ok(result.data)
    } catch (error: any) {
      throw error?.response?.data
    }
  }

  //   public deleteFavorite = async (id: string): Promise<Result<void>> => {
  //     try {
  //       await this.service.delete(`/favorite/${id}`)

//       return Result.ok()
//     } catch (error: any) {
//       throw error?.response?.data
//     }
//   }
}
